import Team from "./Team";
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Typography,
    Tooltip,
} from "@material-tailwind/react";

export default function Ourteam() {
    return (
        <div className="">
            <div className="relative isolate overflow-hidden bg-white px-6 py-12 sm:py-12 lg:overflow-visible lg:px-0">
                <div className="sm:mb-8 sm:flex sm:justify-center">
                    <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-indigo-400 ring-1 ring-gray-900/10 hover:ring-gray-900/20 text-center">
                        Meet our most dedicated and hardworking team members.
                        <a href="#" className="font-semibold text-indigo-600">
                            <span className="absolute inset-0" aria-hidden="true" />
                        </a>
                    </div>
                </div>
                <div className="text-center">
                    <h1 className="mt-5 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                        Our Team Members
                    </h1>
                </div>
            </div>
            < Team />
        </div>
    );
}

