import { CheckIcon } from '@heroicons/react/20/solid'

import Pie from './pie'
import pieimage from './pie.png'

const includedFeatures = [
  'Retail',
  'Industrial',
    'Mixed Use',
    'Land/Special Use',
]

export default function Example() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Diversified Portfolio</h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
              By Establishing a varied range of investments in target sectors, ME has a board but specialized approach.
              This give us ablity to perform exceptionally and withstanding market fluctuations.
            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">Included in all plans</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {includedFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className='flex justify-center'>
                <img src={pieimage} alt="pie" />
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
