import React, { useState } from "react";
import newYorkImage from "./GEOGRAPHY.png";

export default function WorkingLocation() {

  return (
    <div className="flex flex-col items-center justify-center text-center">
      <h1 className="text-3xl font-bold text-gray-900">Our Working Locations</h1>
      <p className="mt-5 block text-sm font-normal text-gray-700 px-5">
        ME Real Estate invests exclusively in geographic regions where we have a local presence.
      </p>

      <div className="mt-5 block text-sm font-light text-gray-700">
        We have a strong presence in the following regions:
      </div>
      <div className="mt-5 block text-sm font-medium text-gray-800">
        <ul className="flex flex-row items-center justify-center text-center gap-5">
          <li>
            <div className="flex flex-row items-center justify-center text-center gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-3 w-3 text-indigo-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2 10a8 8 0 1116 0 8 8 0 01-16 0zm8 7a7 7 0 100-14 7 7 0 000 14z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10 12a2 2 0 100-4 2 2 0 000 4z"
                />
              </svg>
              Ohio
            </div>
          </li>
          <li>
            <div className="flex flex-row items-center justify-center text-center gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-3 w-3 text-indigo-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2 10a8 8 0 1116 0 8 8 0 01-16 0zm8 7a7 7 0 100-14 7 7 0 000 14z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10 12a2 2 0 100-4 2 2 0 000 4z"
                />
              </svg>
              Inidiana
            </div>

          </li>
          <li>
            <div className="flex flex-row items-center justify-center text-center gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-3 w-3 text-indigo-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2 10a8 8 0 1116 0 8 8 0 01-16 0zm8 7a7 7 0 100-14 7 7 0 000 14z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10 12a2 2 0 100-4 2 2 0 000 4z"
                />
              </svg>
              New York
            </div>
          </li>
          <li>
            <div className="flex flex-row items-center justify-center text-center gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-3 w-3 text-indigo-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2 10a8 8 0 1116 0 8 8 0 01-16 0zm8 7a7 7 0 100-14 7 7 0 000 14z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10 12a2 2 0 100-4 2 2 0 000 4z"
                />
              </svg>
              Wisconsin
            </div>
          </li>
        </ul>
      </div>

      <div className="mt-2">
        <div className="relative w-[400px] h-[500px] sm:w-[800px] sm:h-[550px]">
          <img src={newYorkImage} alt="New York" className="object-cover object-center w-full h-full" />
        </div>
      </div>
    </div>
  );
}
