import React from "react";
import { Button } from "@material-tailwind/react";
import cover from "./cover.jpg";

function CoverPage() {
  return (
    <div className="h-72 sm:h-screen">
      <div className="relative overflow-hidden h-72 sm:h-full">
        <div className="absolute inset-0">
          <img
            src="https://images.unsplash.com/photo-1487958449943-2429e8be8625?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80"
            alt="Background Image"
            className="object-cover object-center sm:w-full sm:h-full h-56 w-full"
          />
        </div>

        <div className="relative flex flex-col items-center h-full text-center p-4 sm:p-8 sm:justify-center">
          <h1 className="sm:text-4xl text-xl font-bold title-font mb-4 mt-5">
            ME Real Estate
          </h1>
          <p className="text-sm sm:text-base mb-8">
            Discover the world of real estate with ME Real Estate
          </p>
          <Button
            variant="filled"
            color="white"
            size="sm"
            onClick={() => {
              window.location.href = "/companyprofile";
            }}
            className="flex items-center gap-2"
          >
            Read More{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
              />
            </svg>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CoverPage;
